import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid'
import classnames from 'classnames'
import React from 'react'
import { analytics } from '../../helpers/analytics'
import { Decimal, Market, Ticker, Token, getConfigs, useAppSelector } from 'web-sdk'
import CustomLink from 'components/CustomLink'

export interface TickerItemProps {
  market: Market
  tickers: {
    [pair: string]: Ticker
  }
  position: number | string
  handleDragEnter: (event: any) => void
  handleDragEnd: () => void
  handleDrag: (event: any) => void
}

export const TickerItem: React.FC<TickerItemProps> = ({
  market,
  tickers,
  position,
  handleDrag,
  handleDragEnd,
  handleDragEnter,
}: TickerItemProps) => {
  const currentMarketTicker = tickers[market.id]
  const tokens = useAppSelector(state => state.tokens.list)

  const name = React.useMemo(() => {
    const asset = tokens.find((token: Token) => token.symbol_id === market.base_unit)

    return (asset && asset.name) || ''
  }, [tokens])

  const renderPriceChangeArrow = React.useCallback((value: number) => {
    const cxPriceChangePercent = classnames('flex items-center px-1 py-0.5 rounded-sm mr-2 w-[34.4px]', {
      'bg-bid-10 text-bid-60': value >= 0,
      'bg-ask-10 text-ask-60': value < 0,
    })

    return (
      <span className={cxPriceChangePercent}>
        {value >= 0 ? (
          <ArrowUpIcon width={30} className="h-5 mr-0.5" />
        ) : (
          <ArrowDownIcon width={30} className="h-5 mr-0.5" />
        )}
      </span>
    )
  }, [])

  const renderPriceChange = React.useCallback(
    (lastPrice: number, change: number) => {
      const cxPriceChangePercent = classnames('flex items-center text-sm rounded-sm leading-[0.9rem]', {
        'text-bid-60': change >= 0,
        'text-ask-60': change < 0,
      })

      const changeSign = change < 0 ? '-' : '+'
      const priceChange = (lastPrice * change) / 100

      return [
        <span key={1} className={classnames(cxPriceChangePercent, 'font-metro-semibold')}>
          {changeSign}
          <Decimal fixed={2} thousSep=",">
            {Math.abs(change)}
          </Decimal>
          %
        </span>,
        <span key={2} className={cxPriceChangePercent}>
          {changeSign}
          <Decimal fixed={market.price_precision} thousSep=",">
            {Math.abs(priceChange)}
          </Decimal>
        </span>,
      ]
    },
    [market.price_precision],
  )

  const sendAnalytics = () => {
    analytics({
      type: 'otherEvent',
      name: 'main_page_ticker_click',
      params: {
        market_id: market.id,
        base_currency: market.base_unit,
        qoute_currency: market.quote_unit,
        ticker_position: Number(position),
      },
    })
  }

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragEnd={handleDragEnd}
      onDrag={handleDrag}
      className="cursor-pointer active:cursor-grabbing"
    >
      <CustomLink href={`/trading/${market.id}`}>
        <a
          onClick={sendAnalytics}
          className="flex border draggableItem hideDraggedItemDetails border-divider-color-20 rounded p-2 mr-2 hover:shadow-md hover:shadow-divider-color-20"
        >
          {renderPriceChangeArrow(currentMarketTicker?.price_change_percent || 0)}
          <div className="flex flex-col mr-3">
            <p className="h-[20px] flex items-center capitilize leading-4">
              <span className="text-base whitespace-nowrap font-metro-bold mr-1 text-text-color-100">{name}</span>
              <span className="text-sm font-metro-semibold text-text-color-70">
                {market.base_unit.toLocaleUpperCase()}
              </span>
              <span className="text-sm font-metro-semibold text-text-color-70">/{market.quote_unit.toUpperCase()}</span>
            </p>

            <span className="font-metro-semibold leading-[0.9rem] text-sm text-text-color-70">
              {getConfigs().platformCharSymbol}
              {currentMarketTicker?.last ? (
                <Decimal fixed={market.price_precision} thousSep=",">
                  {currentMarketTicker?.last}
                </Decimal>
              ) : (
                0
              )}
            </span>
          </div>
          <div className="flex flex-col items-end">
            {renderPriceChange(+currentMarketTicker?.last || 0, currentMarketTicker?.price_change_percent || 0)}
          </div>
        </a>
      </CustomLink>
    </div>
  )
}
